<template>
    <b-container class="pb-5">
        <b-row class="mt-3">
            <b-col></b-col>
            <b-col align="left" cols="12" md="8">
                <h2 class="mb-5 mt-3">MyResorts Terms and Conditions:</h2>
                <h3>Important Information</h3>
                <b-container>
                    <b-row>
                        <b-col cols="12" md="6">
                            <h5 class="mb-3">Check In and Check Out</h5>
                            <ul class="dashed">
                                <li>Arrival Time 14:00</li>
                                <li>Check-out Time 10:00</li>
                                <li>Early arrival and late departures available at a fee, 
                                by prior arrangement
                                </li>
                            </ul>
                        </b-col>
                        <b-col cols="12" md="6">
                            <h5>Arrival and Check In</h5>
                            <ul class="dashed">
                                <li>All guests will be required to sign a compulsory indemnity agreement on arrival</li>
                                <li>Proof of identification required upon arrival </li>
                            </ul>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="6">
                            <h5 class="mb-3">Booking Rates</h5>
                            <ul class="dashed">
                                <li>Packages are indicated in South African Rand unless otherwise stipulated</li>
                                <li>Rates include all relevant taxes and levies as per South African Law</li>
                                <li>Rates are subject to change</li>
                            </ul>
                        </b-col>
                        <b-col cols="12" md="6">
                            <h5>Room allocation & occupancy</h5>
                            <ul class="dashed">
                                <li>The maximum number of guests a room can accommodate cannot be exceeded</li>
                                <li>Rooms do not have self-catering facilities</li>
                            </ul>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="6">
                            <h5 class="mb-3">Activities and Excursions</h5>
                            <ul class="dashed">
                                <li>All activities and excursions are subject to 
                                availability, are weather permitting and take place 
                                at the discretion of management and guides
                                </li>
                                <li>Bookings are essential </li>
                            </ul>
                        </b-col>
                        <b-col cols="12" md="6">
                            <h5>Restaurant Serving Times</h5>
                            <ul class="dashed">
                                <li>Meals are served buffet-style depending on hotel occupancy</li>
                                <li>Breakfast serving time between 07:00 – 09:30</li>
                                <li>Lunch serving time between 12:30 – 14:30</li>
                                <li>Dinner serving time between 9:30 – 21:30 </li>
                                <li>A la carte menu available daily from 07:30</li>
                            </ul>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="6">
                            <h5 class="mb-3">Child Policy</h5>
                            <ul class="dashed">
                                <li>Children 13 years of age and older are considered an adult and will be charged adult rates

                                </li>
                                <li>Children 3 – 12 years of age pay child rates</li>
                                <li>Infants (0-2 years) sharing with adult/s stay free</li>
                            </ul>
                        </b-col>
                        <b-col cols="12" md="6">
                            <h5>Health and Safety</h5>
                            <ul class="dashed">
                                <li>Evacuation procedure is displayed in accommodation rooms and public areas. </li>
                                <li>Please report emergencies or concerns to Reception immediately</li>
                            </ul>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
            <b-col></b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>