import Vue from 'vue'
import Vuex from 'vuex'

import Resorts from './Booking/resort'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
      resort: Resorts,
  },
})
