import Vue from 'vue'
import VueRouter from 'vue-router'
import PrivacyPolicy from '../views/Polocies/PrivacyPolicyView.vue'
import RefundPolicy from '../views/Polocies/RefundPolicyView.vue'
import TermsConditions from '../views/Polocies/TermsConditions.vue'
import ContactUs from '../views/ContactUsView.vue'
//import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactUs
  },
  {
    path: '/',
    name: 'Resorts',
    component: () => import('../views/Resort/ResortsListView.vue')
  },
  {
    path: '/resort/',
    name: 'ResortDetailView',
    component: () => import('../views/Resort/ResortDetailView.vue'),
    props: {}
  },
  {
    path: '/bookNow/',
    name: 'BookNow',
    component: () => import('../views/Booking/BookNow.vue'),
    props: {}
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('../views/MapView.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-conditions',
    name: 'TermsConditions',
    component: TermsConditions
  },
  {
    path: '/refund-policy',
    name: 'RefundPolicy',
    component: RefundPolicy
  },
]

const router = new VueRouter({
  routes
})

export default router
