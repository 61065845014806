<template>
    <b-container class="mt-5 pb-5">
        <b-row class="mb-2">
            <b-col>
                <h2>Contact Us</h2>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="12" lg="6">
                <MapWithPin/>
            </b-col>
            <b-col class="mt-2" cols="12" md="12" lg="6">
                <h4 class="mb-3">Lombardy Business Park</h4>
                <h5>C/o Graham and Cole Road</h5>
                <h5>Pretoria</h5>
                <h5>Gauteng</h5>
                <h5>0084</h5>
                <h5>South Africa</h5>
                <h5 class="mt-3">hello@myresorts.co.za</h5>
                <h5>(+27)79-874 6741</h5>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import MapWithPin from '../components/MapComponent.vue';
    export default {
        components: {
            MapWithPin,
        },
    
    }
</script>

<style lang="scss" scoped>

</style>