<template>
  <div>
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14369.21296238656!2d28.3613618!3d-25.7935681!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e955df97fb26a9d%3A0x281881be175d24b0!2sLombardy%20Business%20Park!5e0!3m2!1sen!2sza!4v1722856978242!5m2!1sen!2sza" width="500" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
</template>

<script>
export default {
  
  data() {
    return {
      center: { lat: 40.712776, lng: -74.005974 }, // Default to New York City
    };
  },
};
</script>