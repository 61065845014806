<template>
    <b-container class="pb-5">
        <b-row class="mt-3">
            <b-col></b-col>
            <b-col align="left" cols="12" md="8">
                <h2>MyResorts Privacy Policy</h2> 
                <p class="mt-5">We respect your privacy. 
                    This Privacy Policy governs the manner in which 
                    we will treat your personal information collected 
                    when using our website and when we otherwise engage 
                    with each after you visit our website
                </p>
                <h5>How do we collect your personal information?</h5>
                <p class="mt-3">
                    Your personal information may be collected while using this website, 
                    when you contact us, when you complete an application form, 
                    when you check in at your destination, when you make a booking 
                    or when you make use of the services of one of our affiliated business partners. 
                    Personal information includes any information by which you can be identified and which 
                    relates to you as an identifiable individual, such as your name, physical and postal addresses, 
                    email address, sex, age and internet address from the domain from which you are visiting.
                </p>
                <h5>Types of information collected</h5>
                <p class="mt-3">
                    When you register on this website we collect the specific information provided by you
                     in relation the to services that you requested. This information may include, but is not limited to:
                </p>
                <ul class="dashed">
                    <li>Full names</li>
                    <li>Contact details (E-mail address and telephone number)</li>
                    <li>Home address</li>
                    <li>Affiliation</li>
                    <li>Date of birth</li>
                    <li>Reason for using the site</li>
                    <li>ID / passport number</li>
                </ul>
                <p>This information will be either collected electronically or by using cookies.</p>
                <h5>Use of your personal information</h5>
                <p class="mt-3">We need to process your personal information when you make use of our service. 
                    We use your personal information in the ordinary course of our business and to 
                    send you marketing material relating to our service offering 
                    (direct marketing will only take place if you opted into receiving such marketing). 
                    You can unsubscribe at any time and we will thereafter no longer use your personal
                     information. We will not disclose your personal information to third parties unless 
                     justifiable in terms of agreement between us.
                </p>
                <h5>Access to and correction of personal information</h5>
                <p class="mt-3">
                    You may access your personal information that we hold by sending an email to <a href="mailto:hello@MyResorts.co.za?subject=Access%20Personal%20Information&body=I%20would%20like%20to%20access%20my%20personal%20information" target="_top">hello@MyResorts.co.za</a>. 
                </p>
                <p>
                    In the event that you want to update or correct your personal information held by us, you can send 
                    an email to <a href="mailto:hello@MyResorts.co.za?subject=Update%20Personal%20Information&body=I%20would%20like%20to%20update%20my%20personal%20information" target="_top">hello@MyResorts.co.za</a>. 
                </p>
                <h5>Protection of your personal information</h5>
                <p class="mt-3">
                    We take reasonable steps and measures to keep your information secure from loss, 
                    \misuse or unauthorised alteration. Our database has built-in safeguards to ensure 
                    the privacy and confidentiality of your information. We do not have control over the
                     websites of affiliated parties or sites linked into from this site and you must therefore
                      refer to the privacy policies of any such parties to see how they protect your personal information.
                </p>
                <h5>Cookies</h5>
                <p class="mt-3">
                    We may use cookies. Cookies are programs that we transfer to your computer’s 
                    hard drive to personalise your experience on this website. In this way, the
                     next time you visit, we will know who you are and that you have visited this 
                     website before. This will enable us to enhance your browsing experience. 
                     You can set your browser to notify you if cookies are to be transferred or 
                     to reject cookies but this may prevent your use of some of the web pages.
                </p>
                <h5>Email Monitoring</h5>
                <p class="mt-3">
                    We provide email addresses on the website which enable you to contact us. 
                    Forms that you complete via this website will also be transmitted via email. 
                    In order to check for viruses and to monitor service we reserve the right to 
                    monitor and inspect all material and information transmitted over our system.
                </p>
                <h5>Changes to this privacy policy</h5>
                <p class="mt-3">
                    We reserve the right, in our sole discretion, to amend this Privacy Policy 
                    from time to time. You agree to review the Privacy Policy whenever you visit
                     this website for any such amendments. The amended version of the Privacy Policy
                      shall supersede and replace all previous versions thereof.
                </p>
                <h5>Which laws apply to your dealings with us?</h5>
                <p class="mt-3">
                    This policy will be governed and interpreted in accordance with the laws of South Africa.
                     To the extent that a court has jurisdiction over any dispute which may arise out of or in
                      connection with this Privacy Policy, we both submit to the jurisdiction of the South African Courts.
                </p>
            </b-col>
            <b-col>

            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>