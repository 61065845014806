<template>
    <b-container class="pb-5">
        <b-row class="mt-3">
            <b-col></b-col>
            <b-col align="left" cols="12" md="8">
                <h2 class="mt-5 mb-3">MyResorts Booking Policies:</h2>
                <h4 class="mb-3"><strong>Deposit Policy</strong></h4>
                <h5 class="mb-3">Leisure Bookings</h5>
                <ul class="dashed mb-3">
                    <li>50% deposit payment of the total invoice amount is requested within 48 hours to confirm a booking</li>
                    <li>Remaining balance is due 7 days before arrival</li>
                </ul>
                <h5 class="mb-3">Leisure Group Bookings (10 rooms or more)</h5>
                <ul class="dashed mb-3">
                    <li>50% deposit payment of the total invoice amount is requested within 48 hours to confirm a booking</li>
                    <li>Remaining balance is due 7 days before arrival</li>
                </ul>
                <h5 class="mb-3">Corporate Bookings</h5>
                <ul class="dashed mb-3">
                    <li>Account Holders: LPO within 48 hours from requesting the booking</li>
                    <li>Non-Account Holders: 50% deposit payment of the total invoice amount is requested withing 21 days from requesting the booking. Remaining balance due 14 days prior to arrival. </li>
                </ul>
                <h5 class="mb-3">Tour Operator & Agent Bookings</h5>
                <ul class="dashed mb-3">
                    <li>As per the contract agreement</li>
                </ul>
                <h4 class="mb-3"><strong>Payment Policy</strong></h4>
                <h5 class="mb-3">Deposit Payment Optioins</h5>
                <ul class="dashed mb-3">
                    <li>Bank Transfer <br>
                        Company: My Resorts (Pty) Ltd <br>
                        Bank:: First National Bank (FNB) <br>
                        Account number: 63100603900 <br>
                        Branch Code: 251345 <br>
                        Swift Code: FIRNZAJJ <br>
                        </li>
                    <li>Secure Credit Card Link
                    Kindly request a secure link from the reservations team 
                    </li>
                    <li>MyResorts does not accept Travelers cheques, Diners club cards, American Express or cheques</li>
                </ul>
                <h5 class="mb-3">Cancellation Policy</h5>
                <ul class="dashed">
                    <li>All cancellations and changes must be done in writing </li>
                    <li>Cancellation fee is applicable to changes in room nights / number of delegates</li>
                    <li>No shows / Guests not arriving for a booking will be charged 100% cancellation penalty</li>
                </ul>
                <h5 class="mb-3">Leisure and Corporate Bookings</h5>
                <ul class="dashed mb-3">
                    <li>15 days or more prior to arrival: 0% of the total quoted amount
                        </li>
                    <li>14 to 8 days prior to arrival: 50% of the total quoted amount
                    </li>
                    <li>7 to 0 days prior to arrival: 100% of the total quoted amount</li>
                </ul>
                <h5 class="mb-3">Group Bookings</h5>
                <ul class="dashed mb-3">
                    <li>30 to 15 Days prior to arrival: 50% of the total quoted amount
                        </li>
                    <li>14 to 0 Days prior to arrival: 100% of the total quoted amount
                    </li>
                </ul>
                <h5 class="mb-3">Tour Operator and Travel agent bookings</h5>
                <ul class="dashed mb-3">
                    <li>As per the contract agreement
                        </li>
                </ul>
                <h5 class="mb-3">Refunds Policy</h5>
                <ul class="dashed">
                    <li>
                        On cancellation of a refundable booking the refund processing will take between 7 – 10 business days.
                    </li>
                    <li>
                        No cash refunds will be permitted.
                    </li>
                </ul>
                <h5 class="mb-3">Short Term Bookings Policy</h5>
                <ul class="dashed">
                    <li>Booking done less than 14 days prior to arrival <br>
                        Account holders –voucher within 48 hours of requesting the booking <br>
                        Non-Account holders – 100% payment within 48 hours of requesting the booking <br>
                        </li>
                        <li>Booking done less than 48 hours prior to arrival <br>
                        Account holders – Same day guarantee or Voucher <br>
                        Non-Account holders – 100% payment on the day of the booking <br>
                        </li>
                </ul>
                <h5 class="mb-3">Indemnity</h5>
                <ul class="dashed">
                    <li>You hereby indemnify the hotel and its employees, directors, officers and /or agents against any loss claims or damage of whatsoever nature which may be suffered by yourself and or any third party arising in anyway from your stay at the Hotel or use of the premises or any of the facilities.</li>
                </ul>
                <RouterLink class="pb-5" to="/privacy-policy">Learn more about our Privacy Policy: </RouterLink>
            </b-col>
            <b-col></b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>