import axios from 'axios'

export default{
    namespaced: true,
    state: {
        resorts: [],
        resort: {},
        images: [],
        resortWeeks: [],
        layout: '',
        description: '',
        resortMedia: undefined,
        resortsWithListings: [],
        loadingStatus: false,
        weeksLoaded: false
    },
    mutations: {
        setResort(state, resort) {
            state.resort = resort
        },
        setDescription(state, description) {
            state.description = description
        },
        addImage(state, image) {
            state.images.push(`data:image/jpeg;base64,${image}`)
        },
        clear(state) {
            state.resort = {}
            state.images = []
            state.layout = ''
        },
        setLayout(state, image) {
            state.layout = `data:image/jpeg;base64,${image}`
        },
        setResorts(state, list) {
            state.resorts = list
        },
        setLoadingStatus(state, newLoadingStatus){
            state.loadingStatus = newLoadingStatus
        },
        setWeeksLoaded(state, newLoadingStatus){
            state.weeksLoaded = newLoadingStatus
        },
        setResortMedia(state, media) {
            state.resortMedia = media
        },
        setResortWeeks(state, weeks){
            state.resortWeeks = weeks
        },
        setResortsWithListings(state, data) {
            state.resortsWithListings = data
        },
    },
    getters: {
        getLoadingStatus(state) {
            return state.loadingStatus
        },
    },
    actions: {
        async initResort({ commit, dispatch }, id) {
            commit('clear')
            await dispatch('getResort', id)
            dispatch('getDescription', id)
            dispatch('getImage1', id)
            dispatch('getImage2', id)
            dispatch('getImage3', id)
            dispatch('getImage4', id)
            dispatch('getImage5', id)
            dispatch('getLayout', id)
        },
        getDescription({ commit }, id) {
            axios
                .get(
                    `https://www.tradeunipoint.com/unibackend/seam/resource/rest/products/${id}/dsc`,
                )
                .then((result) => {
                    commit('setDescription', result.data)
                })
                .catch(console.error)
        },
        getResort({ commit }, id) {
            axios
                .get(
                    `https://www.tradeunipoint.com/unibackend/seam/resource/rest/products/TRESORT/${id}`,
                )
                .then((result) => {
                    commit('setResort', result.data)
                })
                .catch(console.error)
        },
        async getImage1({ commit }, id) {
            return axios
                .get(
                    `https://www.tradeunipoint.com/unibackend/seam/resource/rest/products/${id}/image/0`,
                )
                .then((result) => {
                    commit('addImage', result.data)
                    return Promise.resolve(result.data)
                })
                .catch(console.error)
        },
        getImage2({ commit }, id) {
            axios
                .get(
                    `https://www.tradeunipoint.com/unibackend/seam/resource/rest/products/${id}/image/1`,
                )
                .then((result) => commit('addImage', result.data))
                .catch(console.error)
        },
        getImage3({ commit }, id) {
            axios
                .get(
                    `https://www.tradeunipoint.com/unibackend/seam/resource/rest/products/${id}/image/2`,
                )
                .then((result) => commit('addImage', result.data))
                .catch(console.error)
        },
        getImage4({ commit }, id) {
            axios
                .get(
                    `https://www.tradeunipoint.com/unibackend/seam/resource/rest/products/${id}/image/3`,
                )
                .then((result) => {
                    if (result.data && result.data.length > 0) {
                        commit('addImage', result.data)
                    }
                })
                .catch(console.error)
        },
        getImage5({ commit }, id) {
            axios
                .get(
                    `https://www.tradeunipoint.com/unibackend/seam/resource/rest/products/${id}/image/4`,
                )
                .then((result) => {
                    if (result.data && result.data.length > 0) {
                        commit('addImage', result.data)
                    }
                })
                .catch(console.error)
        },
        getLayout({ commit }, id) {
            axios
                .get(
                    `https://www.tradeunipoint.com/unibackend/seam/resource/rest/products/${id}/layout`,
                )
                .then((result) => commit('setLayout', result.data))
                .catch(console.error)
        },
        async retrieveResortWithWeeks({commit}, searchObject){
            try{
                var promise = new Promise((resolve) => {
                    let list = []
                    searchObject.resortArr.forEach((resort, index) => {
                        
                        var weekPromise = new Promise((resolve) => {
                            let url = `http://www.unipoint-consoft.co.za/nph-srep.exe?clavail.sch&CLUB=LPA&RESORT=${resort.resortCode}&FROMDATE=${searchObject.fromDate}&TODATE=${searchObject.toDate}`
                            const resp = axios.get(url)
                            resolve(resp)
                        })

                        //let trimmed = resp.data.replace("\n", "")
                        weekPromise.then((resp) => {
                            if(resp.data.trim() !== "none"){
                                const weekList = []
                                resp.data.split("<br>").forEach(row => {
                                    const myArray = row.split(",")
    
                                    let dataToSend = []
                                    dataToSend.push(myArray[0])
                                    dataToSend.push(myArray[3])
    
                                    // new Promise((res, rej) => {
                                    //     axios.get()
                                    // })
    
                                    const week = {}
                                    week.ResortCode = myArray[0]
                                    week.Unit = myArray[1]
                                    week.Module = myArray[2]
                                    week.Size = myArray[3]
                                    week.StockId = myArray[4]
                                    week.ManagementFee = myArray[5]
                                    week.CurrentYearArrival = myArray[6]
                                    week.CurrentYearDeparture = myArray[7]
                                    week.Season = myArray[8]
                                    week.Avail = myArray[9]
                                    week.CurrentYearExp = myArray[10]
                                    week.NextYearManagementFee = myArray[11]
                                    week.NextYearArrival = myArray[13]
                                    week.NextYearDeparture = myArray[14]
                                    week.NextYearSeason = myArray[15]
                                    week.NextYearAvail = myArray[16]
                                    week.NextYearExp = myArray[17]
                                    week.Rank = myArray[20]
                                    week.FoundationFee = myArray[21]
                                    week.Instalment = myArray[22]
                                    week.SpecialStock = myArray[23]
                                    week.CalCode = myArray[24]
                                    // if(myArray[9]){
                                    //     if(myArray[9].trim() === 'Y'){
                                    //     }
                                    // }
                                    weekList.push(week)
                                });
    
                                let obj = {
                                    "resortName":resort.resortName,
                                    "town":resort.city,
                                    "weeks":weekList,
                                }
    
                                list.push(obj)
                            }
                        })
                        
                        if(index === searchObject.resortArr.length - 1){
                            resolve(list)
                        }
                    })
                })
                promise.then((result) => {
                    commit('setResortsWithListings', result)
                })
                
            }
            catch(err){
                console.log(err);
            }
        },
        getResorts({ commit }, searchObject) {
            return new Promise((resolve, reject) => {
                commit('setLoadingStatus', true)
                var promise = new Promise((resolve, reject) => {
                    let url = `https://www.tradeunipoint.com/unibackend/seam/resource/rest/products/resorts/${searchObject.regionCode}?disabled=true` 
                    axios
                        .get(url)
                        .then((result) => {  
                            resolve(result)
                        })
                        .catch((error) => reject(new Error(error.message)))
                })
                promise.then((result) => {
                    let resortList = []
                    result.data.forEach(resort => {
                        var selectObj = {
                            "value" : { 
                                resortCode: resort.resortCode,
                                resortID: resort.id
                            },
                            "text" : resort.resortName
                        }
                        resortList.push(selectObj)
                    });
                    var defaultObj = {
                        "value" : null,
                        "text" : "Resort"
                    }
                    resortList.unshift(defaultObj)
                    commit('setResorts', resortList)
                    commit('setLoadingStatus', false)
                    resolve(resortList)
                })
                .catch((error) => reject(new Error(error.message)))
            })
        },
        
        async getResortWeeks({commit}, searchObject){
            commit('setWeeksLoaded', true)
            console.log(searchObject);
            
            var promise = new Promise((resolve, reject) => {
                
                let data = '<file contents here>';
                let url = `https://training.provision-sa.com:83/api/myconsoftresorts/getweeks?resort=${searchObject.resortCode}&arrivalDate=${searchObject.fromDate}&departureDate=${searchObject.toDate}`
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: url,
                    data : data
                  };
                
                axios
                    .request(config)
                    .then((result) => {
                        let weeks = []
                        result.data.forEach(week => {
                            week.ResortId = searchObject.resortId
                            weeks.push(week)
                        });
                        console.log(weeks);
                        
                        commit('setResortWeeks', weeks)
                        resolve()
                    })
                    .catch((error) => reject(new Error(error.message)))
            })

            promise.then(() => {
                commit('setWeeksLoaded', false)
            })
        },
        getResortMedia({ commit }, id) {
            axios
                .get(
                    `https://www.tradeunipoint.com/unibackend/seam/resource/rest/products/social/${id}`,
                )
                .then((result) => commit('setResortMedia', result.data))
        }
    }
}