import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/fontawesome'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetAlert2 from "vue-sweetalert2"
import AOS from 'aos'
import 'aos/dist/aos.css'
import * as VueGoogleMaps from 'vue2-google-maps';
//import axios from 'axios'

Vue.config.productionTip = false
Vue.use(VueSweetAlert2)
//axios.defaults.baseURL = 'http://localhost:57260'
    //axios.defaults.baseURL = "https://www.pvsl.co.za:86/";
    //axios.defaults.baseURL = 'http://training.provision-sa.com:120/'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDOn6H3HCEqaciWXZPWUtMqykCqEBjCOM0',
    libraries: 'places', // necessary for places input
  },
});

new Vue({
  router,
  store,
  mounted() {
    AOS.init()
  },
  render: h => h(App),
}).$mount('#app')
